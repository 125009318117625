<template>
  <div>
    <b-alert show>
      <Zondicon icon="user-solid-circle" style="width: 32px" />
      Cari Yönetimi
      <b-alert
        class="p-0"
        :variant="cari.bakiye == 0 ? 'info' : cari.bakiye > 0 ? 'success' : 'warning'"
        show
        >Bakiye {{ $store.getters.paraFormati(cari.bakiye) }} ₺</b-alert
      >
    </b-alert>
    <b-alert variant="warning" :show="cari.eFatmi && !vknKontrolEdilyor"
      >E-FATURA Mükellefi</b-alert
    >
    <b-alert variant="warning" :show="!cari.eFatmi && !vknKontrolEdilyor"
      >E-ARŞİV Mükellefi</b-alert
    >

    <form @submit.prevent="cariKaydet">
      <b-container fluid class="p-2">
        <b-row>
          <b-col cols="12" right>
            <b-button-toolbar aria-label="Toolbar with button groups and dropdown menu">
              <b-button-group class="mx-1">
                <b-button @click="oncekiCari">&lsaquo;</b-button>
              </b-button-group>
              <b-button-group class="mx-1">
                <b-button type="submit">Kaydet</b-button>
                <b-button>Kopyala</b-button>
                <b-button>Sil</b-button>
              </b-button-group>
              <b-button-group class="mx-1">
                <b-button @click="sonrakiCari">&rsaquo;</b-button>
              </b-button-group>
              <b-dropdown class="mx-1" right text="İşlemler">
                <b-dropdown-item @click="vknKontrol">GİB Kontrol</b-dropdown-item>
              </b-dropdown>
               <b-input-group    >

 <vue-instant :suggestOnAllWords="true"
 suggestion-attribute="unvan"   :disabled="false"    
 v-model="arananCari"
 @enter="arananCariyeGit"
 
 @selected="arananCariyeGit" 
 :show-autocomplete="true" 
 :autofocus="false" 
 :suggestions="$store.state.sirketAyarlar.tumCariler" 
 name="unvan" 
 placeholder="Cariye Geç" type="google"></vue-instant>
          
    </b-input-group>
            </b-button-toolbar>
          </b-col>
        </b-row>
      </b-container>
      <b-container fluid class="m-2 p-2">
        <b-row>
          <b-col cols="12" xl="4" lg="4">
            <div id="faturaGecmis"></div>
          </b-col>
        </b-row>
      </b-container>
      <b-container fluid class="p-2">
        <b-tabs content-class="mt-3">
          <b-tab title="Genel Bilgi" active>
            <b-row>
              <b-col cols="12">
                <b-container fluid>
                  <b-row>
                    <b-col cols="12">
                      Vergi/T.C. No
                      <b-form-input
                        v-model="cari.vn"
                        @keyup="vknKontrol"
                        @blur="$v.cari.vn.$touch()"
                        :class="{ invalidData: $v.cari.vn.$error }"
                      />
                    </b-col>
                  </b-row>
                  <b-row class="m-2">
                    <b-col cols="12" lg="3" xl="3">
                      <b-row>
                        <b-col cols="12">
                          <b-form-checkbox v-model="cari.aktif"> Aktif </b-form-checkbox>
                        </b-col>

                        <b-col cols="12">
                          <b-form-checkbox v-model="cari.vergidenMuaf">
                            Vergiden Muaf
                          </b-form-checkbox>
                        </b-col>

                        <b-col cols="12">
                          <b-form-checkbox v-model="cari.ihracatMusterisi">
                            İhracat Müşterisi
                          </b-form-checkbox>
                        </b-col>

                        <b-col cols="12">
                          Tip

                          <multiselect
                            v-model="cari.tip"
                            :options="$store.state.cariTipler"
                            selectLabel="Seç"
                            deselectLabel="Seçimi Kaldır"
                            label="text"
                            track-by="text"
                          >
                          </multiselect>
                        </b-col>
                        <b-col cols="12">
                          İskonto Oranı

                          <vue-autonumeric
                            class="form-control"
                            style="text-align: right"
                            v-model="cari.faturaIskontoOrani"
                            :options="{
                              currencySymbolPlacement: 's',
                              decimalCharacter: ',',
                              decimalPlaces: 2,
                              digitGroupSeparator: '.',
                              suffixText: '%',
                            }"
                          ></vue-autonumeric>
                        </b-col>
                        <b-col cols="12">
                          Limit
                          <vue-autonumeric
                            class="form-control"
                            style="text-align: right"
                            v-model="cari.krediLimiti"
                            :options="{
                              currencySymbolPlacement: 's',
                              decimalCharacter: ',',
                              decimalPlaces: 4,
                              digitGroupSeparator: '.',
                            }"
                          ></vue-autonumeric>
                        </b-col>

                        <b-col cols="12">
                          <b-row class="p-0">
                            <b-col cols="12"> Ödeme Planı </b-col>

                            <b-col cols="12" xl="5" lg="5" class="p-1">
                              <vue-autonumeric
                                v-model="cari.odemePlanGun"
                                class="form-control"
                                style="text-align: right"
                                :options="{
                                  currencySymbolPlacement: 's',
                                  decimalCharacter: ',',
                                  decimalPlaces: 0,
                                  digitGroupSeparator: '.',
                                }"
                              ></vue-autonumeric>
                            </b-col>
                            <b-col cols="12" xl="7" lg="7" class="p-1">
                              <multiselect
                                v-model="cari.odemePlanTip"
                                :options="$store.state.sirketAyarlar.odemePlanlari"
                                selectLabel="Seç"
                                deselectLabel="Seçimi Kaldır"
                                label="text"
                                track-by="value"
                              >
                              </multiselect>
                            </b-col>
                          </b-row>
                        </b-col>
                        <b-col cols="12">
                          <b-row class="p-0">
                            <b-col cols="12"> Açılış Bakiyesi </b-col>

                            <b-col cols="12" xl="6" lg="6" class="p-1">
                              <vue-autonumeric
                                v-model="cari.acilisBakiyesi"
                                class="form-control"
                                style="text-align: right"
                                :options="{
                                  currencySymbolPlacement: 's',
                                  decimalCharacter: ',',
                                  decimalPlaces: 4,
                                  digitGroupSeparator: '.',
                                }"
                              ></vue-autonumeric>
                            </b-col>
                            <b-col cols="12" xl="6" lg="6" class="p-1">
                              <multiselect
                                v-model="cari.acilisBakiyesiTip"
                                :options="acilisBakiyeTipler"
                                selectLabel="Seç"
                                deselectLabel="Seçimi Kaldır"
                                label="text"
                                track-by="value"
                              >
                              </multiselect>
                            </b-col>
                          </b-row>
                        </b-col>
                      </b-row>
                    </b-col>
                    <b-col cols="12" lg="3" xl="3">
                      <b-col cols="12" class="p-0">
                        Cari Kod
                        <b-input-group size="sm">
                          <template #prepend>
                            <b-button v-b-tooltip title="Otomatik Kod" @click="otokod">
                              <b-icon icon="journal-plus"></b-icon>
                            </b-button>
                          </template>

                          <b-form-input
                            v-model="cari.kod"
                            @blur="$v.cari.kod.$touch()"
                            :class="{ invalidData: $v.cari.kod.$error }"
                          />
                        </b-input-group>
                      </b-col>
                      <b-col cols="12" class="p-0">
                        Bina
                        <b-input-group size="sm">
                          <b-form-input v-model="cari.bina"></b-form-input>
                        </b-input-group>
                      </b-col>
                      <b-col cols="12" class="p-0">
                        Sokak
                        <b-form-input v-model="cari.sokak"> </b-form-input>
                      </b-col>
                      <b-col cols="12" class="p-0">
                        P.K.
                        <b-form-input v-model="cari.pk"> </b-form-input>
                      </b-col>
                      <b-col cols="12" class="p-0">
                        Vergi Dairesi
                        <b-form-input
                          v-model="cari.vd"
                          @state="$v.cari.vd.$touch()"
                          :class="{ invalidData: $v.cari.vd.$error }"
                        >
                        </b-form-input>
                      </b-col>

                      <b-col cols="12" class="p-0">
                        Mersis No
                        <b-form-input v-model="cari.mersisNo"> </b-form-input>
                      </b-col>
                      <b-col cols="12" class="p-0">
                        GLN Kod
                        <b-form-input v-model="cari.glnKod"> </b-form-input>
                      </b-col>
                    </b-col>

                    <b-col cols="12" lg="6" xl="6">
                      <b-row class="m-2">
                        <b-col>
                          Ad/Ünvan
                          <b-form-input
                            v-model="cari.unvan"
                            @blur="$v.cari.unvan.$touch()"
                            :class="{ invalidData: $v.cari.unvan.$error }"
                          >
                          </b-form-input>

                          Adres
                          <b-form-textarea
                            id="textarea"
                            placeholder="Adres..."
                            rows="3"
                            max-rows="6"
                            v-model="cari.adres"
                            @blur="$v.cari.adres.$touch()"
                            :class="{ invalidData: $v.cari.adres.$error }"
                          />
                        </b-col>
                      </b-row>

                      <b-row class="m-2">
                        <b-col>
                          İl
                          <multiselect
                            v-model="cari.il"
                            :options="$store.state.sirketAyarlar.ililceListesi"
                            selectLabel="Seç"
                            @select="ilSecildi"
                            @close="ilSecildi"
                            deselectLabel="Seçimi Kaldır"
                            label="Ad"
                            searchable
                            placeholder="Seçiniz"
                            track-by="Ad"
                            @blur="$v.cari.il.$touch()"
                            :class="{
                              invalidData: $v.cari.il.$error || cari.il.length == 0,
                            }"
                            :allow-empty="false"
                          >
                          </multiselect>
                        </b-col>
                        <b-col>
                          İlce
                          <multiselect
                            v-model="cari.ilce"
                            :options="ilceListesi"
                            selectLabel="Seç"
                            deselectLabel="Seçimi Kaldır"
                            label="ad"
                            nooptions
                            searchable
                            placeholder="Seçiniz"
                            track-by="ad"
                            isTouch="false"
                            @blur="$v.cari.ilce.$touch()"
                            :class="{
                              invalidData: $v.cari.ilce.$error || cari.ilce.length == 0,
                            }"
                            :allow-empty="false"
                          >
                          </multiselect>
                        </b-col>
                        <b-col>
                          Ülke
                          <b-input-group size="sm">
                            <b-form-input v-model="cari.ulke"></b-form-input>
                          </b-input-group>
                        </b-col>
                      </b-row>
                      <b-row class="m-2">
                        <b-col>
                          Mail Adresleri
                          <p>
                            <span class="text-muted"
                              >Birden Fazla Mail adresi tanımlayabilirsiniz</span
                            >
                          </p>
                          <multiselect
                            v-model="cari.mailler"
                            tag-placeholder="Mail Adresi Ekle"
                            placeholder="Mail Adresi Ekle"
                            label="mail"
                            track-by="mail"
                            :options="cari.mailler"
                            :multiple="true"
                            :taggable="true"
                            @tag="addTagMail"
                          ></multiselect>
                        </b-col>
                      </b-row>

                      <b-row class="m-2">
                        <b-col>
                          Telefonlar
                          <p>
                            <span class="text-muted"
                              >Birden Fazla Telefon tanımlayabilirsiniz</span
                            >
                          </p>
                          <multiselect
                            v-model="cari.telefonlar"
                            tag-placeholder="Telefon Ekle"
                            placeholder="Telefon Ekle"
                            label="tel"
                            track-by="tel"
                            :options="cari.telefonlar"
                            :multiple="true"
                            :taggable="true"
                            @tag="addTagTel"
                          ></multiselect>
                        </b-col>
                      </b-row>
                      <b-row class="m-2">
                        <b-col>
                          Web Sayfaları
                          <p>
                            <span class="text-muted"
                              >Birden Fazla Web Sayfası tanımlayabilirsiniz</span
                            >
                          </p>
                          <multiselect
                            v-model="cari.webler"
                            tag-placeholder="Web Sayfası Ekle"
                            placeholder="Web Sayfası Ekle"
                            label="web"
                            track-by="web"
                            :options="cari.webler"
                            :multiple="true"
                            :taggable="true"
                            @tag="addTagWeb"
                          ></multiselect>
                        </b-col>
                      </b-row>

                      <b-row class="m-2">
                        <b-col>
                          Kategoriler
                          <p>
                            <span class="text-muted"
                              >Birden Fazla Kategori tanımlayabilirsiniz</span
                            >
                          </p>
                          <multiselect
                            v-model="cari.kategoriler"
                            tag-placeholder="Kategori Tanımla"
                            placeholder="Kategori Tanımla"
                            label="kat"
                            track-by="kat"
                            :options="cari.kategoriler"
                            :multiple="true"
                            :taggable="true"
                            @tag="addTagKategori"
                          ></multiselect>
                        </b-col>
                      </b-row>
                    </b-col>
                  </b-row>
                </b-container>
              </b-col>
            </b-row>

            <b-row class="m-2" v-show="cari.eFatmi">
              <b-col cols="12">
                <b-card-group deck>
                  <b-card title="E-Fatura Bilgileri" sub-title="">
                    <b-card-text> E-Fatura Senaryosu </b-card-text>
                    <multiselect
                      v-model="cari.efat_eDnusumTipId"
                      :options="$store.state.entitiler.efatSenaryolar"
                      selectLabel="Seç"
                      deselectLabel="Seçimi Kaldır"
                      label="text"
                      track-by="text"
                    >
                    </multiselect>

                    <b-card-text>Default PK.</b-card-text>
                    <multiselect
                      v-model="cari.efat_defaultpk"
                      :options="efat_defaultpkList"
                      selectLabel="Seç"
                      deselectLabel="Seçimi Kaldır"
                      label="text"
                      track-by="text"
                    >
                    </multiselect>
                  </b-card>
                  <b-card title="E-İrsaliye Bilgileri" sub-title="">
                    <b-card-text> Default PK. </b-card-text>
                    <multiselect
                      v-model="cari.eirs_defaultpk"
                      :options="eirs_defaultpkList"
                      selectLabel="Seç"
                      deselectLabel="Seçimi Kaldır"
                      label="text"
                      track-by="text"
                    >
                    </multiselect>
                    <b-card-text>Taşıyıcı Firma</b-card-text>
                    <b-input v-model="cari.eirs_tasiyiciFirmaId"></b-input>
                    <b-card-text>Teslim Şekli</b-card-text>
                    <b-input v-model="cari.eirs_teslimSekli"></b-input>
                  </b-card>
                </b-card-group>
              </b-col>
            </b-row>

            <b-row class="m-2" v-show="cari.ihracatMusterisi">
              <b-col cols="12">
                <b-card title="İhracat Bilgileri" sub-title="">
                  <b-row>
                    <b-col xl="3" lg="3">
                      Gönderim Şekli
                      <multiselect
                        v-model="cari.ihracat_gonderimSekilleriId"
                        :options="$store.state.entitiler.ihracat.gonderimSekilleri"
                        selectLabel="Seç"
                        deselectLabel="Seçimi Kaldır"
                        label="text"
                        track-by="text"
                      >
                      </multiselect>
                    </b-col>
                    <b-col xl="3" lg="3">
                      Teslim Şartı
                      <multiselect
                        v-model="cari.ihracat_teslimSartlariId"
                        :options="$store.state.entitiler.ihracat.teslimSartlari"
                        selectLabel="Seç"
                        deselectLabel="Seçimi Kaldır"
                        label="text"
                        track-by="text"
                      >
                      </multiselect>
                    </b-col>
                    <b-col xl="3" lg="3">
                      Kabın Markası
                      <b-input v-model="cari.ihracat_kabinMarkasi"></b-input>
                    </b-col>

                    <b-col xl="3" lg="3">
                      Kabın Cinsi
                      <multiselect
                        v-model="cari.ihracat_kabCinsleri"
                        :options="$store.state.sirketAyarlar.global_kabCinsleri"
                        selectLabel="Seç"
                        deselectLabel="Seçimi Kaldır"
                        :custom-label="kabCinsLabels"
                        label="tanim"
                        track-by="tanim"
                      >
                        <template slot="option" slot-scope="props">
                          <span class="option__title">{{ props.option.tanim }}</span>
                          <span class="option__small">{{ props.option.code }}</span>
                        </template>
                      </multiselect>
                    </b-col>
                  </b-row>
                </b-card>
              </b-col>
            </b-row>
          </b-tab>
          <b-tab title="Şube">
            <b-row>
              <b-col>
                <b-button variant="primary" @click="subeEkle">Yeni</b-button>
              </b-col>
            </b-row>
            <b-card v-for="sube in cari.subeler" :key="sube.Id" class="m-2">
              <cari-sube v-bind:sube="sube" @subeSil="subeSil($event)"></cari-sube>
            </b-card>
            <b-alert :show="cari.subeler.length <= 0" variant="warning"
              >Kayıt Bulunamadı</b-alert
            >
          </b-tab>
          <b-tab title="Web/Sosyal">
            <b-row>
              <b-col cols="12">
                <b-form-checkbox v-model="cari.b2bAktif"> B2B 'de Aktif </b-form-checkbox>
              </b-col>
              <b-col cols="12">
                <b-form-checkbox v-model="cari.b2bKKAktif">
                  B2B 'de Kredi Kartı ile ALışveriş Aktif
                </b-form-checkbox>
              </b-col>
            </b-row>

            <b-row>
              <b-col cols="12" xl="3" lg="3">
                B2B Fiyat İşlemi
                <multiselect
                  v-model="cari.b2bFiyatTipi"
                  tag-placeholder="B2B Fiyat"
                  placeholder="B2B Fiyat"
                  label="text"
                  track-by="text"
                  :options="b2bFiyatIslemTipleri"
                ></multiselect>
              </b-col>
              <b-col cols="12" xl="3" lg="3">
                Tutar/Oran
                <b-form-input v-model="cari.b2bFiyatTutarOran"> </b-form-input>
              </b-col>
            </b-row>

            <b-row>
              <b-col cols="12" xl="3" lg="3">
                B2B İndirim İşlemi
                <multiselect
                  v-model="cari.b2bIndirimFiyatTipi"
                  tag-placeholder="B2B Fiyat"
                  placeholder="B2B Fiyat"
                  label="text"
                  track-by="text"
                  :options="b2bFiyatIslemTipleri"
                ></multiselect>
              </b-col>
              <b-col cols="12" xl="3" lg="3">
                Tutar/Oran
                <b-form-input v-model="cari.b2bIndirimFiyatTutarOran"> </b-form-input>
              </b-col>
            </b-row>
          </b-tab>
        </b-tabs>
      </b-container>
    </form>
  </div>
</template>

<script>
import VueAutonumeric from "../ozel/VueAutonumeric.vue";
import AutoNumeric from "autonumeric";
import { required } from "vuelidate/lib/validators";
import cariSube from "./cariSube";
import { all, allSettled } from "q";
import { getEventListeners } from "events";

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

am4core.useTheme(am4themes_animated);
 
export default {
  components: {
    VueAutonumeric,
    cariSube,
    
  },
  data() {
    return {
      arananCari:'',
      cari: {
        Id: this.$route.params.id,
        b2bAktif: false,
        b2bKKAktif: false,
        b2bFiyatTipi: { value: 0, text: "Fiyat" },
        b2bFiyatTutarOran: 0,
        b2bIndirimFiyatTipi: { value: 0, text: "Fiyat" },
        b2bIndirimFiyatTutarOran: 0,
        kayitTarihi: new Date(),
        unvan: null,
        kod: "",
        adres: "",
        pk: "",
        ihracatMusterisi: false,
        aktif: true,
        vergidenMuaf: false,
        resim: "",
        vd: null,
        faturaIskontoOrani: 99,
        vn: "",
        il: [],
        ilce: [],
        ulke: "",
        tip: {},
        mailler: [],
        telefonlar: [],
        webler: [],
        kategoriler: [],
        bakiye: 0,
        eFatmi: false,
        odemePlanTip: { value: 0, text: "Gün" },
        krediLimiti: 0,
        odemePlanGun: 7,
        efat_eDnusumTipId: 0,
        efat_defaultpk: null,
        eirs_defaultpk: null,
        eirs_tasiyiciFirmaId: null,
        eirs_teslimSekli: "",
        ihracat_kabinMarkasi: "",
        ihracat_gonderimSekilleriId: 0,
        ihracat_kabCinsleri: 0,
        subeler: [],
        acilisBakiyesi: 0,
        glnKod: "",
        mersisNo: "",
        acilisBakiyesiTip: { value: 0, text: "Borçlu" },
      },
      vknKontrolEdilyor: true,
      efat_defaultpkList: [],
      eirs_defaultpkList: [],
      b2bFiyatIslemTipleri: [
        { value: 0, text: "Fiyat" },
        { value: 1, text: "Yüzde" },
      ],
      acilisBakiyeTipler: [
        { value: 0, text: "Borçlu" },
        { value: 1, text: "Alacaklı" },
      ],
      ilceListesi: [],
    };
  },
  methods: {
    arananCariyeGit(){
       
        var d = this.$store.state.sirketAyarlar.tumCariler.filter((o)=> o.unvan == this.arananCari)
         if(d.length>0){
          this.$router.push("/Cari/" + d[0].Id);
         }
       
    },
    grafik_fatura() {
      var carininFaturalari = this.$store.state.sirketAyarlar.tumFaturalar.filter(
        (o) => o.cariId == this.cari.Id
      );
      var filtred = carininFaturalari;
      console.log(carininFaturalari);
      var aylar = [
        "Ocak",
        "Şubat",
        "Mart",
        "Nisan",
        "Mayıs",
        "Haziran",
        "Temmuz",
        "Ağustos",
        "Eylül",
        "Ekim",
        "Kasım",
        "Aralık",
      ];

      var data = [];

      for (var i = 0; i < 12; i++) {
        filtred = carininFaturalari.filter(
          (fl) => new Date(fl.faturaTarihi).getMonth() == i
        );
        if (filtred.length > 0) {
          var ayToplamTutar = 0;
          filtred.forEach((element) => {
            ayToplamTutar += parseFloat(element.toplamTutar);
          });

          data.push({
            ay: aylar[i] + " " + filtred.length + " Adet ",
            value: ayToplamTutar,
          });
        }
      }

      if (data.length > 0) {
        var chart = am4core.create("faturaGecmis", am4charts.PieChart);
        // Add data
        chart.data = data;

        // Add and configure Series
        var pieSeries = chart.series.push(new am4charts.PieSeries());
        pieSeries.dataFields.value = "value";
        pieSeries.dataFields.category = "ay";
      }
    },
    cariTemizle() {
      this.arananCari ='';

      this.cari = {
        Id: this.$route.params.id,
        b2bAktif: false,
        b2bKKAktif: false,
        b2bFiyatTipi: { value: 0, text: "Fiyat" },
        b2bFiyatTutarOran: 0,
        b2bIndirimFiyatTipi: { value: 0, text: "Fiyat" },
        b2bIndirimFiyatTutarOran: 0,
        kayitTarihi: new Date(),
        unvan: null,
        kod: "",
        adres: "",
        pk: "",
        ihracatMusterisi: false,
        aktif: true,
        vergidenMuaf: false,
        resim: "",
        vd: null,
        faturaIskontoOrani: 99,
        vn: "",
        il: [],
        ilce: [],
        ulke: "",
        tip: {},
        mailler: [],
        telefonlar: [],
        webler: [],
        kategoriler: [],
        bakiye: 0,
        eFatmi: false,
        odemePlanTip: { value: 0, text: "Gün" },
        krediLimiti: 0,
        odemePlanGun: 7,
        efat_eDnusumTipId: 0,
        efat_defaultpk: null,
        eirs_defaultpk: null,
        eirs_tasiyiciFirmaId: null,
        eirs_teslimSekli: "",
        ihracat_kabinMarkasi: "",
        ihracat_gonderimSekilleriId: 0,
        ihracat_kabCinsleri: 0,
        subeler: [],
        acilisBakiyesi: 0,
        glnKod: "",
        mersisNo: "",
        acilisBakiyesiTip: { value: 0, text: "Borçlu" },
      };
    },
    cariOku() {
      this.$store.state.loadAnimAc = true;

      this.cariTemizle();

      setTimeout(() => {
        if (this.cari.Id == 0) {
          this.otokod();
          this.$store.state.loadAnimAc = false;
        } else {
          var gelenCari = this.$store.state.sirketAyarlar.tumCariler.find(
            (o) => o.Id == this.cari.Id
          );

          if (gelenCari.mailler) {
            gelenCari.mailler.split(",").forEach((element) => {
              this.addTagMail(element);
            });
          }
          if (gelenCari.telefonlar) {
            gelenCari.telefonlar.split(",").forEach((element) => {
              this.addTagTel(element);
            });
          }
          if (gelenCari.webler) {
            gelenCari.webler.split(",").forEach((element) => {
              this.addTagWeb(element);
            });
          }
          if (gelenCari.kategoriler) {
            gelenCari.kategoriler.split(",").forEach((element) => {
              this.addTagKategori(element);
            });
          }

          if (gelenCari.il && gelenCari.il > 0) {
            var ili = this.$store.state.sirketAyarlar.ililceListesi.find(
              (o) => o.Id == gelenCari.il
            );
            if (ili) {
              this.cari.il = ili;

              this.ilSecildi();

              if (gelenCari.ilce && gelenCari.ilce > 0) {
                this.cari.ilce = this.cari.il.ilceleri.find(
                  (o) => o.Id == gelenCari.ilce
                );
              }
            }
          }

          this.cari.tip = this.$store.state.cariTipler.filter(
            (o) => o.value === parseInt(gelenCari.tip)
          )[0];
          this.cari.odemePlanTip = this.$store.state.sirketAyarlar.odemePlanlari.filter(
            (o) => o.value === parseInt(gelenCari.odemePlanTip)
          )[0];

          this.cari.b2bAktif = gelenCari.b2bAktif;
          this.cari.b2bKKAktif = gelenCari.b2bKKAktif;

          //  "b2bFiyatTipi": 0, "b2bFiyatTutarOran": 100, "b2bIndirimFiyatTipi": 1, "b2bIndirimFiyatTutarOran": 0
          this.cari.b2bFiyatTipi = this.b2bFiyatIslemTipleri.filter(
            (o) => o.value == parseInt(gelenCari.b2bFiyatTipi)
          )[0];

          this.cari.b2bFiyatTutarOran = gelenCari.b2bFiyatTutarOran;
          this.cari.b2bIndirimFiyatTipi = this.b2bFiyatIslemTipleri.filter(
            (o) => o.value == parseInt(gelenCari.b2bIndirimFiyatTipi)
          )[0];
          this.cari.b2bIndirimFiyatTutarOran = gelenCari.b2bIndirimFiyatTutarOran;
          this.cari.unvan = gelenCari.unvan;
          this.cari.kod = gelenCari.kod;
          this.cari.adres = gelenCari.adres;
          this.cari.ihracatMusterisi = gelenCari.ihracatMusterisi;
          this.cari.aktif = gelenCari.aktif;
          this.cari.vergidenMuaf = gelenCari.vergidenMuaf;
          this.cari.resim = "";
          this.cari.vd = gelenCari.vd;
          this.cari.faturaIskontoOrani = parseFloat(gelenCari.faturaIskontoOrani);
          this.cari.vn = gelenCari.vn;

          this.cari.ulke = gelenCari.ulke;

          this.cari.bakiye = gelenCari.bakiye;
          this.cari.eFatmi = gelenCari.eFatmi;
          this.cari.krediLimiti = gelenCari.krediLimiti;
          this.cari.odemePlanGun = gelenCari.odemePlanGun;
          this.cari.efat_eDnusumTipId = this.$store.state.entitiler.efatSenaryolar.filter(
            (o) => o.value == gelenCari.efat_eDnusumTipId
          );
          this.cari.kayitTarihi = gelenCari.kayitTarihi;
          this.efat_defaultpkList = [];
          this.eirs_defaultpkList = [];

          if (gelenCari.efat_defaultpk) {
            if (gelenCari.efat_defaultpk.length > 1) {
              this.efat_defaultpkList.push({
                value: 1,
                text: gelenCari.efat_defaultpk,
              });

              this.cari.efat_defaultpk = {
                value: 1,
                text: gelenCari.efat_defaultpk,
              };
            }
          }

          if (gelenCari.eirs_defaultpk) {
            if (gelenCari.eirs_defaultpk.length > 1) {
              this.eirs_defaultpkList.push({
                value: 1,
                text: gelenCari.eirs_defaultpk,
              });

              this.cari.eirs_defaultpk = {
                value: 1,
                text: gelenCari.eirs_defaultpk,
              };
            }
          }

          this.cari.eirs_tasiyiciFirmaId = gelenCari.eirs_tasiyiciFirmaId;
          this.cari.eirs_teslimSekli = gelenCari.eirs_teslimSekli;
          this.cari.ihracat_kabinMarkasi = gelenCari.ihracat_kabinMarkasi;
          this.cari.ihracat_gonderimSekilleriId = this.$store.state.entitiler.ihracat.gonderimSekilleri.filter(
            (o) => o.value == gelenCari.ihracat_gonderimSekilleriId
          );
          this.cari.ihracat_kabCinsleri = this.$store.state.sirketAyarlar.global_kabCinsleri.filter(
            (o) => o.Id == gelenCari.ihracat_kabCinsleri
          );

          gelenCari.subeler.forEach((element) => {
            this.cari.subeler.push(element);
          });

          this.cari.acilisBakiyesiTip = this.acilisBakiyeTipler.filter(
            (o) => o.value == (gelenCari.acilisBakiyesi <= 0 ? 0 : 1)
          )[0];
          this.cari.acilisBakiyesi = Math.abs(gelenCari.acilisBakiyesi);

          this.cari.glnKod = gelenCari.glnKod;
          this.cari.mersisNo = gelenCari.mersisNo;
          this.cari.pk = gelenCari.pk;
          this.cari.bina = gelenCari.bina;
          this.cari.sokak = gelenCari.sokak;
          this.vknKontrolEdilyor = false;
          this.grafik_fatura();
          this.$store.state.loadAnimAc = false;
        }
      }, 300);
    },
    oncekiCari() {
      var s = this.$store.state.sirketAyarlar.tumCariler.findIndex(
        (o) => o.Id == this.cari.Id
      );

      if (s > 0) {
        var sonrakiCarisi = this.$store.state.sirketAyarlar.tumCariler[s - 1];

        this.$router.push("/Cari/" + sonrakiCarisi.Id);
      } else {
        this.$dialog.alert("İlk Cari", {
          okText: "TAMAM",
        });
      }
    },
    sonrakiCari() {
      var s = this.$store.state.sirketAyarlar.tumCariler.findIndex(
        (o) => o.Id == this.cari.Id
      );

      if (s == this.$store.state.sirketAyarlar.tumCariler.length - 1) {
        this.$dialog.alert("Son Cari", {
          okText: "TAMAM",
        });
      } else {
        var sonrakiCarisi = this.$store.state.sirketAyarlar.tumCariler[s + 1];
        this.$router.push("/Cari/" + sonrakiCarisi.Id);
      }
    },
    subeSil(Id) {
      var th = this;
      this.$dialog
        .confirm("Şube Kaydı Silinecektir.Onaylıyor musunuz", {
          okText: "Onaylıyorum",
          cancelText: "İptal",
        })
        .then(() => {
          th.$store.state.loadAnimAc = true;

          setTimeout(() => {
            var qs = require("qs");

            var config = {
              method: "post",
              url: "/api/carisRoute/subeSil?subeId=" + Id,
              headers: {
                "Content-Type": "application/x-www-form-urlencoded",
              },
              data: qs.stringify({
                userMail: th.$store.state.userMail,
                userSifre: th.$store.state.userSifre,
                sirketId: th.$store.state.calisilanSirketId,
              }),
            };

            axios(config)
              .then((res) => {
                th.$store.state.loadAnimAc = false;
                /* var anaCari = th.$store.state.sirketAyarlar.tumCariler.filter((o)=> o.Id == th.cari.Id).subeler*/

                var ind = th.cari.subeler.findIndex((o) => o.Id == Id);

                th.cari.subeler.splice(ind, 1);

                th.$store.dispatch("sirketCariler", { cariId: th.cari.Id });
              })
              .catch((error) => {
                th.$store.state.loadAnimAc = false;
                th.$dialog.alert("Şube Silinemedi.");
              });
          }, 1000);
        });
    },
    subeEkle() {
      this.cari.subeler.push({
        Id: 0, //"Y-" + new Date().getTime().toString(),
        tanim: "",
        adres: "",
        il: 1,
        ilce: 1,
        bina: "",
        sokak: "",
        pk: "",
        ulke: "",
      });
    },
    otokod() {
      var kod = new Date().getTime().toString();

      this.cari.kod = "M-" + kod.substr(kod.length - 8, 8);
    },
    vknKontrolYap() {
      this.vknKontrolEdilyor = true;
      if (this.cari.vn.length > 9) {
        setTimeout(() => {
          if (this.cari.vn.length == 10) {
            this.$store.state.loadAnimAc =true;
            var urlsi = "/api/gib/gibCariKontrol?vkn=" + this.cari.vn;
            var qs = require("qs");
            var data = qs.stringify({
              userMail: this.$store.state.userMail,
              userSifre: this.$store.state.userSifre,
              sirketId: this.$store.state.calisilanSirketId,
            });
            var config = {
              method: "post",
              url: urlsi,
              headers: {
                "Content-Type": "application/x-www-form-urlencoded",
              },
              data: data,
            };
            console.log("vkn alaınıyor");
            var th = this;
            axios(config)
              .then((res) => {
                this.$store.state.loadAnimAc =false
                if (res.data.data != null) {
                  this.cari.eFatmi = true;
                  this.cari.efat_eDnusumTipId = { value: 0, text: "Temel Fatura" };

                  if (res.data.data.aktifEtiket) {
                    if (res.data.data.aktifEtiket.length > 1) {
                      this.efat_defaultpkList.push({
                        value: 1,
                        text: res.data.data.aktifEtiket,
                      });
                      this.cari.efat_defaultpk = {
                        value: 1,
                        text: res.data.data.aktifEtiket,
                      };
                    }
                  }

                  if (res.data.data.aktifEtiket) {
                    if (res.data.data.aktifEtiket.length > 1) {
                      this.eirs_defaultpkList.push({
                        value: 1,
                        text: res.data.data.aktifEtiket,
                      });

                      this.cari.eirs_defaultpk = {
                        value: 1,
                        text: res.data.data.aktifEtiket,
                      };
                    }
                  }

                  this.cari.unvan = res.data.data.unvan;
                } else {
                  this.efat_defaultpkList = [];
                  this.eirs_defaultpkList = [];
                  this.cari.efat_eDnusumTipId = { value: 2, text: "E-Arşiv" };
                  this.cari.efat_defaultpk = null;
                  this.cari.eirs_defaultpk = null;
                }
                this.vknKontrolEdilyor = false;
              })
              .catch(function () {
                  this.$store.state.loadAnimAc =false

              });
          } else {
            this.cari.eFatmi = false;
            this.vknKontrolEdilyor = false;
            this.$store.state.loadAnimAc =false
          }
        }, 200);
      }
    },
    cariKaydet() {
      var subeBilgiEksik = 0;

      this.cari.subeler.forEach((element) => {
        if (element.tanim == "" || element.adres == "" || !element.il || !element.ilce) {
          subeBilgiEksik += 1;
        }
      });

      if (subeBilgiEksik > 0) {
        this.$dialog.alert("Şube Bilgilerinde eksik.Lütfen kontrol ediniz.", {
          okText: "Tamam",
        });
      } else {
        this.$v.$touch();
        if (this.$v.$invalid) {
          this.$dialog.alert("Zorunlu Alanları doldurunuz", {
            okText: "Tamam",
          });
        } else {
          if (
            this.$store.state.sirketAyarlar.tumCariler.filter(
              (o) => o.tcvkn == this.cari.vn
            ).length > 0
          ) {
            this.$dialog.alert(
              "Bu TC/VKN numarası zaten Kayıtlarınızda Mevcut.Lütfen kontrol ediniz",
              {
                okText: "Kapat",
              }
            );
            return;
          }

          this.$store.state.loadAnimAc = true;

          var urlsi = "/api/carisRoute/cariKaydet";

          var gidecekSubeler = [];

          if (this.cari.subeler.length > 0) {
            this.cari.subeler.map(function (element, index) {
              var sb = {
                Id: element.Id,
                tanim: element.tanim,
                adres: element.adres,
                ilId: element.il,
                ilceId: element.ilce,
                bina: element.bina,
                sokak: element.sokak,
                pk: element.pk,
              };
              /* console.log("Şube  " +  index + " " + sb)
              this.cari.subeler[index] = sb; */
              gidecekSubeler.push(sb);
              element = sb;
            });
          }
         
          var carModel = {
            Id: this.cari.Id,
            b2bAktif: this.cari.b2bAktif,
            b2bKKAktif: this.cari.b2bKKAktif,
            b2bFiyatTipi: this.cari.b2bFiyatTipi.value,
            b2bFiyatOran:
              this.cari.b2bFiyatTutarOran == "" ? 0 : this.cari.b2bFiyatTutarOran,
            b2bIndirimFiyatTipi: this.cari.b2bIndirimFiyatTipi.value,
            b2bIndirimFiyatOran:
              this.cari.b2bIndirimFiyatTutarOran == ""
                ? 0
                : this.cari.b2bIndirimFiyatTutarOran,
            unvan: this.cari.unvan,
            kod: this.cari.kod,
            adres: this.cari.adres,
            ihracatMusterisi: this.cari.ihracatMusterisi,
            aktif: this.cari.aktif,
            vergidenMuaf: this.cari.vergidenMuaf,
            resim: "",
            vd: this.cari.vd,
            faturaIskontoOrani:
              this.cari.faturaIskontoOrani == "" ? 0 : this.cari.faturaIskontoOrani,
            vn: this.cari.vn,
            il: this.cari.il.Id,
            ilce: this.cari.ilce.Id,
            ulke: this.cari.ulke,
            tip: this.cari.tip.value,
            mailler:
              this.cari.mailler.length > 0
                ? this.cari.mailler
                    .map(function (val) {
                      return val.mail;
                    })
                    .join(",")
                : null,
            telefonlar:
              this.cari.telefonlar.length > 0
                ? this.cari.telefonlar
                    .map((val) => {
                      return val.tel;
                    })
                    .join(",")
                : null,
            webler:
              this.cari.webler.length > 0
                ? this.cari.webler
                    .map((val) => {
                      return val.web;
                    })
                    .join(",")
                : null,
            kategoriler:
              this.cari.kategoriler.length > 0
                ? this.cari.kategoriler
                    .map((val) => {
                      return val.kat;
                    })
                    .join(",")
                : null,
            bakiye: 0,
            eFatmi: this.cari.eFatmi,
            odemePlanTip: this.cari.odemePlanTip.value,
            krediLimiti:
              this.cari.krediLimiti == null || this.cari.krediLimiti == ""
                ? 0
                : this.cari.krediLimiti,
            odemePlanGun: this.cari.odemePlanGun,
            efat_eDnusumTipId: this.cari.efat_eDnusumTipId.value,
            efat_defaultpk:
              this.cari.efat_defaultpk != null ? this.cari.efat_defaultpk : null,
            eirs_defaultpk:
              this.cari.eirs_defaultpk != null ? this.cari.eirs_defaultpk : null,
            eirs_tasiyiciFirmaId:
              this.cari.eirs_tasiyiciFirmaId == null
                ? -1
                : this.cari.eirs_tasiyiciFirmaId,
            eirs_teslimSekli: this.cari.eirs_teslimSekli,
            ihracat_kabinMarkasi: this.cari.ihracat_kabinMarkasi,
            ihracat_gonderimSekilleriId: this.cari.ihracat_gonderimSekilleriId.value,

            ihracat_kabCinsleri: this.cari.ihracat_kabCinsleri.Id,
            subeler: gidecekSubeler.length > 0 ? gidecekSubeler : null,
            glnKod: this.cari.glnKod,
            bina: this.cari.bina,
            sokak: this.cari.sokak,
            pk: this.cari.pk,
            mersisno: this.cari.mersisNo,
            acilisBakiyesi: this.cari.acilisBakiyesi,
            acilisBakiyesiTip: this.cari.acilisBakiyesiTip.value,

            kayitTarihi: this.cari.kayitTarihi,
          };

          var data = JSON.stringify({
            user: {
              userMail: this.$store.state.userMail,
              userSifre: this.$store.state.userSifre,
              sirketId: this.$store.state.calisilanSirketId,
            },
            cariModel: carModel,
          });

          var config = {
            method: "post",
            url: urlsi,
            headers: {
              "Content-Type": "application/json",
            },
            data: data,
          };

          var th = this;

          axios(config)
            .then((res) => {
              
              th.$store.state.loadAnimAc = false;
              if (res.data.sonuc) {
                th.$store.dispatch("sirketCariler", { cariId: th.cari.Id });

                th.$store.dispatch("makeToast", { mesaj: "Kaydedildi" });
              } else {
                th.$dialog.alert(res.data.mesaj, {
                  okText: "TAMAM",
                });
              }
            })
            .catch(function (error) {
              console.log(error);
            });
        }
      }
    },
    kabCinsLabels({ tanim, code }) {
      return `${tanim} – ${code}`;
    },
    addTagMail(newTag) {
      const tag = {
        mail: newTag,
      };
      this.cari.mailler.push(tag);
    },
    addTagTel(newTag) {
      const tag = {
        tel: newTag,
      };
      this.cari.telefonlar.push(tag);
    },
    addTagWeb(newTag) {
      const tag = {
        web: newTag,
      };
      this.cari.webler.push(tag);
    },
    addTagKategori(newTag) {
      const tag = {
        kat: newTag,
      };
      this.cari.kategoriler.push(tag);
    },
    ilSecildi() {
      this.ilceListesi = [];
      this.cari.ilce = [];

      this.ilceListesi = this.cari.il.ilceleri;
    },
    vknKontrol() {
      return this.vknKontrolYap();
    },
  },
  validations: {
    cari: {
      vn: {
        required,
      },
      vd: {
        required,
      },
      kod: {
        required,
      },
      unvan: {
        required,
      },
      adres: {
        required,
      },
      il: {
        required,
        $each: {
          required,
        },
      },
      ilce: {
        required,
        $each: {
          required,
        },
      },
    },
  },
  created() {
    this.cariOku();
  },
  watch: {
    $route(to, from) {
      this.cari.Id = to.params.id;
      this.cariOku();
    },
  },
  beforeDestroy() {
    if (this.chart) {
      this.chart.dispose();
    }
  },
};
</script>

<style scoped></style>

<template>
  <div>
    <b-container fluid class="m-2">
      <b-row>
        <b-col cols="12" right>
          <b-button-toolbar key-nav aria-label="Toolbar with button groups">
            <b-button-group class="mx-1">
              <b-button @click="subeSil(sube.Id)">Sil</b-button>
            </b-button-group>
          </b-button-toolbar>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12">
        
          Tanım
          <b-form-input
            v-model="sube.tanim"
            :input="$v.sube.tanim.$touch()"
            @blur="$v.sube.tanim.$touch()"
            :class="{ invalidData: $v.sube.tanim.$error }"
          ></b-form-input>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12" xl="4" lg="4">
          Adres
          <b-form-textarea
            v-model="sube.adres"
            placeholder="Adres..."
            rows="3"
            max-rows="6"
            :input="$v.sube.adres.$touch()"
            @blur="$v.sube.adres.$touch()"
            :class="{ invalidData: $v.sube.adres.$error }"
          />
        </b-col>
        <b-col cols="12" xl="8" lg="8">
          <b-row>
            <b-col cols="12" xl="4" lg="4">
              Bina
              <b-input v-model="sube.bina"></b-input>
            </b-col>
            <b-col cols="12" xl="4" lg="4">
              Sokak
              <b-input v-model="sube.sokak"></b-input>
            </b-col>
            <b-col cols="12" xl="4" lg="4">
              P.K.
              <b-input v-model="sube.pk"></b-input>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" xl="4" lg="4">
              İl
              <multiselect
                v-model="ili"
                :options="iller"
                selectLabel="Seç"
                @close="ilSecildi"
                deselectLabel="Seçimi Kaldır"
                label="Ad"
                searchable
                placeholder="Seçiniz"
                track-by="Id"
                @blur="$v.sube.il.$touch()"
                :class="{
                  invalidData: $v.sube.il.$error || sube.il.length == 0,
                }"
                :allow-empty="false"
              >
              </multiselect>
            </b-col>
            <b-col cols="12" xl="4" lg="4">
              İlçe

              <multiselect
                v-model="ilcesi"
                @close="ilceSecildi"
                :options="ilceler"
                selectLabel="Seç"
                deselectLabel="Seçimi Kaldır"
                label="ad"
                nooptions
                searchable
                placeholder="Seçiniz"
                track-by="ad"
                isTouch="false"
                @blur="$v.sube.ilce.$touch()"
                :class="{
                  invalidData: $v.sube.ilce.$error || sube.ilce.length == 0,
                }"
                :allow-empty="false"
              >
              </multiselect>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-container>
 
  </div>
</template>
<script>
import { required } from "vuelidate/lib/validators";
export default {
  data() {
    return {
      iller:this.$store.state.sirketAyarlar.ililceListesi,
      ilceler:null,
      ili:null,
      ilcesi:null

    }
    
  },
  props: {
    sube: {
      Id: 0,
      tanim: null,
      adres: null,
      il: null,
      ilce: null,
      bina: "",
      sokak: "",
      pk: "",
    },
  },
  validations: {
    sube: {
      il: {
        required,
      },
      ilce: {
        required,
      },
      tanim: {
        required,
      },
      adres: {
        required,
      },
    },
  },
  methods: {
    ilSecildi() {
       this.sube.ilce=0
      this.ilceler=null
      this.ilcesi = []
    
      this.ilceler =this.ili.ilceleri  
      this.sube.il = this.ili.Id
        
    },
    ilceSecildi(){
console.log("b")
        this.sube.ilce = this.ilcesi.Id
    },
    subeSil(Id) {
      
      this.$emit("subeSil", this.sube.Id);
    },
  },
  created() {
    
   
 
    if(this.sube.Id>0){
        
        this.ili = this.$store.state.sirketAyarlar.ililceListesi.find(
          (o) => o.Id == this.sube.il
        );
         
        this.ilceler = this.$store.state.sirketAyarlar.ililceListesi.filter((o)=>o.Id == this.ili.Id)[0].ilceleri
      
        this.ilcesi = this.ilceler.find((o)=>o.Id == this.sube.ilce)
    }else{

     // alert(this.iller[0].Id)

        this.ili=this.iller[0]
       
        //console.log(this.$store.state.sirketAyarlar.ililceListesi.filter((o)=>o.Id == this.sube.il.Id)[0].ilceleri)
        this.ilceler = this.iller[0].ilceleri   
        this.ilcesi = this.ilceler[0]
      
    }
    
       
       
        //this.ilSecildi();

       
       
  },
};
</script>
<style></style>
